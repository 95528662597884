import {Fragment, useState} from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon, UserIcon } from '@heroicons/react/24/outline'
import LoadingData from "../LoadingData";
import GetData from "../../pages/api/get_data";
import {useRouter} from "next/router";
import GetBaseUrl from "../GetBaseUrl";
import Image from "next/image";
import {ShoppingCartIcon} from "@heroicons/react/24/solid";
import CartNavbar from "../configurazione/ordine/cartNavbar";
import Link from "next/link";
import Footer from "../Layout/footer";
import {useSession} from "next-auth/react";
import {Tooltip} from "@mui/material";
// import CartNavbar from "../configurazione/ordine/cartNavbar";

const user = {
    name: 'Tom Cook',
    email: 'tom@example.com',
    // imageUrl:
    //     'logo_jdee.png',
    icon: UserIcon,
}
const userNavigation = [
    // { name: 'Your Profile', href: '#' },
    { name: 'Ordini', href: '/order/list', login_required:true },
    { name: 'Impostazioni', href: '/', login_required:true },
    { name: 'Login', href: '/authentication/signin', login_required:false },
    { name: 'Esci', href: '/api/auth/signout', login_required:true },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TopNavbar({children, scrollable, hide_cart}) {
    const base_url = GetBaseUrl();
    const router = useRouter()
    const {data: session, status} = useSession()

    const [openCart, setOpenCart] = useState(false)
    var current_page = router.asPath;


    const {data:data_classi, error} = GetData(base_url + 'api/classi/?primarie=true')

    let navigation = [
        { name: 'Home', href: '/', current: true, visibile: true },
    ]

    if (error) return <div>failed to load</div>
    if (!data_classi || status === "loading") return <LoadingData/>

    // let isStaff;
    // if(session){
    //     isStaff = session.user.is_staff
    // }
    data_classi.results.map((item) => {
        navigation.push({name: item.cod_cl, href: `/classi/${item.cod_cl}`, current: false, visibile: item.visibile})
    })
    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
            <div className={classNames(
                'h-screen ',
                !scrollable ? 'overflow-hidden ' : ''
            )}>
                <Disclosure as="nav" className="bg-gray-800">
                    {({ open }) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <Link href={'https://www.configuratore.com/'}>
                                                <Image height={80} width={80}
                                                       className="h-8 w-auto"
                                                       src="/logo_jdee.png"
                                                       priority={true}
                                                       alt="Your Company"
                                                />
                                            </Link>
                                        </div>
                                        <div className="hidden md:block">
                                            <div className="ml-10 flex items-baseline space-x-4">
                                                {navigation.map((item) =>
                                                    (
                                                        item.visibile ?
                                                            // <Tooltip key={item.name}
                                                            //          title={item.visibile ? '' : 'Prodotto non visibile ai clienti'}
                                                            //          placement="bottom">
                                                                    <a
                                                                        key={item.href}
                                                                        href={item.href}
                                                                        className={classNames(
                                                                            current_page === item.href
                                                                                ? 'bg-gray-900 text-white'
                                                                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                            'px-3 py-2 rounded-md text-sm font-medium',
                                                                            item.visibile ? '' : 'bg-yellow-600 hover:bg-yellow-700'
                                                                        )}
                                                                        aria-current={current_page === item.href ? 'page' : undefined}
                                                                    >

                                                                        {item.name}
                                                                    </a>
                                                            :
                                                            <></>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">
                                            <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                                                {/*{status === 'authenticated' &&*/}
                                                {/*    <button*/}
                                                {/*        type="button"*/}
                                                {/*        className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"*/}
                                                {/*    >*/}
                                                {/*        <span className="absolute -inset-1.5" />*/}
                                                {/*        <span className="sr-only">Notifiche</span>*/}
                                                {/*        <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                                                {/*    </button>*/}
                                                {/*}*/}

                                                {/* Profile dropdown */}
                                                <Menu as="div" className="relative ml-3">
                                                    <div>
                                                        <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                            <span className="absolute -inset-1.5" />
                                                            <span className="sr-only">Open user menu</span>
                                                            <UserIcon className="h-8 w-8 rounded-full text-gray-400 hover:text-white border-2 border-gray-400 p-1" />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-200"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >

                                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            {userNavigation.map((item) => (
                                                                    !item.login_required  && status === 'unauthenticated' || item.login_required && status === 'authenticated' ?
                                                                        <Menu.Item key={item.name}>
                                                                            {({active}) => (
                                                                                <a
                                                                                    href={item.href}
                                                                                    className={classNames(
                                                                                        active ? 'bg-gray-100' : '',
                                                                                        'block px-4 py-2 text-sm text-gray-700'
                                                                                    )}
                                                                                >
                                                                                    {item.name}
                                                                                </a>
                                                                            )}
                                                                        </Menu.Item>
                                                                            : <></>
                                                            ))}
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                            {/* carrello */}
                                            { !hide_cart &&
                                                <CartNavbar open={openCart} setOpen={setOpenCart}/>
                                            }

                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="md:hidden">
                                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                                    {navigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            href={item.href}
                                            className={classNames(
                                                current_page === item.href ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                'block px-3 py-2 rounded-md text-base font-medium'
                                            )}
                                            aria-current={current_page === item.href ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                </div>
                                    <div className="mt-3 space-y-1 px-2">
                                        {userNavigation.map((item) => (
                                            item.login_required && status === 'unauthenticated' ? <></> :
                                                !item.login_required ?
                                                <Disclosure.Button
                                                    key={item.name}
                                                    as="a"
                                                    href={item.href}
                                                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                                >
                                                    {item.name}
                                                </Disclosure.Button>
                                            : <></>
                                        ))}
                                    </div>
                                {/*</div>*/}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                {/*<header className="bg-white shadow">*/}
                {/*    <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">*/}
                {/*        <h1 className="text-3xl font-bold tracking-tight text-gray-900">Dashboard</h1>*/}
                {/*    </div>*/}
                {/*</header>*/}
                <main>
                    <div className="mx-auto max-w-screen">
                        {/* Replace with your content */}
                        <div className="lg:py-3 md:py-0 sm:px-0">
                        {/*<div className="mx-auto max-w-7xl sm:px-6 lg:px-8">*/}
                            {children}
                        </div>
                        {/* /End replace */}
                    </div>
                </main>
            </div>
            {/*<Footer/>*/}
        </>
    )
}
