import {signOut, useSession} from "next-auth/react";
import PostData from "../pages/api/post_data";
import GetBaseUrl from "./GetBaseUrl";


export default function getAuthToken() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data: session } = useSession();
    const base_url = GetBaseUrl();


    if (!!session) {
        if (!!session.error){
            // PostData(base_url + 'api/clear_session/', {logout:true}).then((result) => {
                signOut({
                    callbackUrl: '/',
                    redirect: true
                })
                return null
            // }).catch((e) => console.log(e));
        }
        return session.accessToken
    }
    return null
}