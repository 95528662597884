import Spinner from "./Spinner";

export default function LoadingData(){
    return (
        <button type="button"
                className="inline-flex items-center rounded border border-transparent bg-gray-600 
                px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none 
                focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                disabled>
            <Spinner hidden={false}/>
            Processing...
        </button>
    )
}