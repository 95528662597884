import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import LoadingData from "./LoadingData";

export default function UseAuth({children}) {
    const { data: session, status } = useSession();
    const router = useRouter();
    const {back } = router.query;

    const [isAuthenticated, setIsAuthenticated] = useState(false);



    useEffect(() => {

        const hideContent = () => setIsAuthenticated(false);
        router.events.on('routeChangeStart', hideContent);

        if (session?.error === "RefreshAccessTokenError") {
            signOut({ callbackUrl: '/authentication/signin', redirect: true });
        }

        if (session === null) {
            if (router.route !== '/authentication/signin') {
                router.replace(`/authentication/signin/?back=${router.asPath}`);
            }
            setIsAuthenticated(false);
        } else if (session !== undefined) {

            // if (session.user.passwordScaduta){
            //     router.push(
            //         {
            //             pathname: '/authentication/resetPassword',
            //             query: {psw_scaduta: true}
            //         }
            //     )
            // }
            if (router.route === '/authentication/signin') {
                back ? router.replace(back) :
                    router.replace('/');
            }
            setIsAuthenticated(true);
        }
    }, [router, session]);

    if (status === "loading") {
        return <LoadingData/>
    }
    return children;
}
