import {MinusIcon, PlusIcon} from "@heroicons/react/20/solid";
import PostData from "../../../pages/api/post_data";
import GetBaseUrl from "../../GetBaseUrl";
import {useState} from "react";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import getAuthToken from "../../getToken";

export default function CartItemQta({...props}) {
    const base_url = GetBaseUrl();
    const token = getAuthToken();
    const {product, productIdx, mutateCart} = props;
    const [productQta, setProductQta] = useState(product.qta_tot)
    const [manualChanged, setManualChanged] = useState(false)
    const [tmpVal, setTmpVal] = useState(product.qta_tot)

    function handleManualChange(value) {
        if (value < 1) {
            setTmpVal(1)
        } else {
            setTmpVal(value)
        }
        if (!manualChanged) {
            setManualChanged(true)
        }
    }

    function removeItem() {
        let data = {
            productIdx: productIdx,
            'action': 'remove'
        }

        PostData(base_url + `api/cart/`, data, token).then((response) => {
            // toast('Articolo rimosso', {type: "success"})
            mutateCart()
        }).catch((error) => {
            console.log(error)
        })

    }

    function changeQta(qta) {
        let data = {
            productIdx: productIdx,
            'action': 'changeQta',
            'qta': qta

        }

        PostData(base_url + `api/cart/`, data, token).then((response) => {
            setProductQta(qta)
            setTmpVal(qta)
            setManualChanged(false)
            mutateCart()
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <div className="flex flex-1 items-end justify-between text-sm">

            <p className="text-gray-500">Quantità </p>
            <span className="isolate inline-flex rounded-md shadow-sm h-5">
               <button
                   type="button"
                   hidden={true}
                   onClick={() => changeQta(productQta - 1)}
                   className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
               >
                <span className="sr-only">Previous</span>
                <MinusIcon className="h-5 w-5" aria-hidden="true"/>
              </button>

                <input
                    type={'number'}
                    onChange={(e) => handleManualChange(e.target.value)}
                    className="relative -ml-px inline-flex
                  items-center text-center bg-white px-3 py-2 text-sm
                  font-semibold text-gray-900 ring-1
                  w-12 input_number_no_arrow
                  ring-inset ring-gray-300 focus:z-10"
                    value={tmpVal}
                />
                {manualChanged ?
                    <button
                        className="relative -ml-px inline-flex
                  items-center bg-white px-3 py-2 text-sm
                  font-semibold text-gray-900 ring-1
                  w-12 input_number_no_arrow
                  ring-inset ring-gray-300 focus:z-10"
                        onClick={(e) => changeQta(tmpVal)}

                    >
                        <CheckCircleIcon
                            className="h-5 w-5 text-green-400" aria-hidden="true"/>
                    </button>

                    : null}

                <button
                    type="button"
                    onClick={() => changeQta(productQta + 1)}
                    className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2
                    text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                >
                <span className="sr-only">Next</span>
                <PlusIcon className="h-5 w-5" aria-hidden="true"/>
              </button>
            </span>

            <div className="flex">
                <button
                    type="button"
                    onClick={() => removeItem()}
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                    Rimuovi
                </button>
            </div>
        </div>
    )
}