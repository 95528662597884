import { useSession } from "next-auth/react";
import GetBaseUrl from "./GetBaseUrl";
import axios from "axios";
import {fetcher_no_auth} from "../pages/api/get_data";
import {useEffect, useState} from "react";



export default async function getTenant() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const { data: session } = useSession();
    const base_url = GetBaseUrl();

    return  await fetcher_no_auth([base_url + 'api/tenant']).then((res) => {
            return res.results[0];
        }).catch((err) => {
            console.log(err)
        });

}