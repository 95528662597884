import TopNavbar from "../menu/TopNavbar";
import UseAuth from "../useAuth";

export default function Layout({children, login_required=false, hide_cart=false, scrollable=true}){
    return (
        <TopNavbar scrollable={scrollable} hide_cart={hide_cart}>
            {login_required ?
                    <UseAuth>
                        {children}
                    </UseAuth>
                :
                   <>
                       {children}
                   </>
            }
        </TopNavbar>
    )
}