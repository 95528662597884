import {Fragment, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {InformationCircleIcon, XMarkIcon} from '@heroicons/react/24/outline'
import GetBaseUrl from "../../GetBaseUrl";
import GetData from "../../../pages/api/get_data";
import LoadingData from "../../LoadingData";
import {ShoppingCartIcon} from "@heroicons/react/24/solid";
import CartItem from "./cartItem";
import getTenant from "../../getTenant";
import {TrashIcon} from "@heroicons/react/20/solid";
import {Tooltip} from "@mui/material";
import PostData from "../../../pages/api/post_data";
import Link from "next/link";
import getAuthToken from "../../getToken";

export default function CartNavbar({open, setOpen}) {

    const token = getAuthToken();
    const base_url = GetBaseUrl();
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
    const [isB2B, setIsB2B] = useState(false)
    const [emptyCart, setEmptyCart] = useState(false)

    getTenant().then((res) => {
        setIsB2B(res.tipo === 'b2b')
    })

    const {data:data, error, mutate} = GetData(base_url + `api/cart`, false, 0, !!token)

    function checkCartEmpty(){
        if (data?.righe?.length === undefined || data?.righe?.length === null || data?.righe?.length === 0) {
            if (!emptyCart) setEmptyCart(true)
        }
        else {
            if (emptyCart) setEmptyCart(false)
        }
    }

    useEffect(() => {
        checkCartEmpty()
    }, [data])

    if (error) return <div>failed to load</div>
    if (!data) return <LoadingData/>

    function handleMutateCart(){
        setShowLoadingSpinner(true)
        mutate(data).then(() => setShowLoadingSpinner(false))
        checkCartEmpty()
    }

    function svuotaCarrello(){
        let data = {
            'action': 'clear'
        }

        PostData(base_url + `api/cart/`, data, token).then((response) => {
            // toast('Articolo rimosso', {type: "success"})
            mutate(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <div>

            <button
                type="button"
                onClick={() =>
                {
                    mutate(data)
                    setOpen(true)
                }
                }

                className="rounded-full bg-gray-800 p-1 ml-4 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            >
            <span className="relative inline-block mt-2"
                  id={'cartButton'}>
                    <ShoppingCartIcon className="h-6 w-6" aria-hidden="true" />
                        {!emptyCart &&
                            <>
                            <span
                                className="absolute top-0 right-0 inline-flex items-center justify-center px-1 py-1 text-xs font-bold
                                                            leading-none text-red-100 transform translate-x-3 -translate-y-1/2 bg-red-600 rounded-full">{data?.righe?.length}</span>
                            </>
                        }
                </span>
            </button>


            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                            <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-gray-900">
                                                        Carrello
                                                    </Dialog.Title>
                                                    { !emptyCart &&
                                                        <div className="ml-3 flex h-7 items-center">
                                                            <Tooltip title={'Svuota carrello'} placement={'left'}>
                                                                <button
                                                                    type="button"
                                                                    className="-m-2 p-2 text-red-400 hover:text-red-500"
                                                                    onClick={() => svuotaCarrello()}
                                                                >
                                                                    <TrashIcon className="h-6 w-6" aria-hidden="true" />
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                </div>


                                                <div className="mt-8">
                                                    <div className="flow-root">
                                                        {
                                                            !emptyCart ?

                                                                showLoadingSpinner ?
                                                                    <LoadingData/>
                                                                    :
                                                                    <ul role="list" className="-my-6 divide-y divide-gray-200">
                                                                        {data.righe?.map((product, productIdx) => (
                                                                            <li key={product.id} className="flex py-6">
                                                                                <CartItem product={product}
                                                                                          mutateCart={handleMutateCart}
                                                                                          productIdx={productIdx}
                                                                                />
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                :
                                                                <div className="rounded-md bg-blue-50 p-4">
                                                                    <div className="flex">
                                                                        <div className="flex-shrink-0">
                                                                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                                                        </div>
                                                                        <div className="ml-3 flex-1 md:flex md:justify-between">
                                                                            <p className="text-sm text-blue-700">Il tuo carrello è vuoto</p>
                                                                            <p className="mt-3 text-sm md:ml-6 md:mt-0">
                                                                                <button
                                                                                    type="button"
                                                                                    className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                                                                                    onClick={() => setOpen(false)}
                                                                                >
                                                                                    Continua a configurare
                                                                                    <span aria-hidden="true"> &rarr;</span>
                                                                                </button>

                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }

                                                    </div>
                                                </div>



                                            </div>

                                            {!emptyCart &&
                                                <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                                        <p>Totale</p>
                                                        <p>{data.totale}€</p>
                                                    </div>
                                                    {/*<p className="mt-0.5 text-sm text-gray-500">Spedizione e tasse saranno calcolate al checkout.</p>*/}
                                                    <div className="mt-6">
                                                        <Link
                                                            href={'/order/checkout'}
                                                            className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                                                        >
                                                            {isB2B ? 'Salva ordine' : 'Checkout'}
                                                        </Link>
                                                    </div>
                                                    <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                                                        <p>
                                                            o {' '}
                                                            <button
                                                                type="button"
                                                                className="font-medium text-indigo-600 hover:text-indigo-500"
                                                                onClick={() => setOpen(false)}
                                                            >
                                                                Continua a configurare
                                                                <span aria-hidden="true"> &rarr;</span>
                                                            </button>
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}
