import axios from "axios";
import useSWR from "swr";
import getAuthToken from "../../components/getToken";
import {useRouter} from "next/router";
import {toast} from "react-toastify";

axios.defaults.withCredentials = true;
const fetcher = (url, token, data) =>
{
    try{
        return axios
            .post(url,
                data,

                { headers: {
                        'Authorization': "Bearer " + token,
                        "Content-Type": "application/json; charset=utf-8",
                    }

                }).catch((error) => {
                    console.log(error)
                if(error.response)
                    for (const [campo, errori] of Object.entries(error.response.data)) {
                        toast("" + campo +": "+errori[0], {type: "error"});
                    }
            })
    }
    catch (e){
        throw new Error(e.response.data.error ? e.response.data.error : e);
    }
}
const fetcher_no_auth = (url, data) =>
{
    try{
        return axios
            .post(url,
                data,

                { headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    }

                }).catch((error) => {
                console.log(error)
                if(error.response)
                    for (const [campo, errori] of Object.entries(error.response.data)) {
                        toast("" + campo +": "+errori[0], {type: "error"});
                    }
            })
    }
    catch (e){
        throw new Error(e.response.data.error ? e.response.data.error : e);
    }
}

export default function PostData(url, data_up, token, file) {
    // let token;
    // if (need_auth){
    //     token = getAuthToken();
    // }

    let form_data;

    if(file!==undefined){
        form_data = new FormData();
        form_data.append('file', file);
        form_data.append('body', JSON.stringify(data_up));
    }
    else {
        form_data = data_up;
    }
    if (token){
        return  fetcher(url, token, form_data)
    }
    return fetcher_no_auth(url, form_data)

}

