
export default function GetBaseUrl(){
    const JDEE_DOMAIN = process.env.JDEE_DOMAIN;

    const hostname =
        typeof window !== 'undefined' && window.location.hostname
            ? window.location.hostname
            : '';
    const protocol =
        typeof window !== 'undefined' && window.location.protocol
            ? window.location.protocol
            : '';
    // const port =
    //     typeof window !== 'undefined' && window.location.port
    //         ? window.location.port
    //         : '';

    if (protocol==='http:'){
        return `${protocol}//${hostname}:8000/`;
    }
    let splitted = hostname.split('.')
    return `${protocol}//${splitted[0]}-${JDEE_DOMAIN}.${splitted.slice(1).join('.')}/`;
}