import CartItemQta from "./cartItemQta";
import {PencilSquareIcon} from "@heroicons/react/24/solid";

export default function CartItem({...props}){
    const {product, productIdx, mutateCart} = props;

    return(
        <>
            <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                <img
                    src={product.img}
                    alt='Product image'
                    className="h-full w-full object-cover object-center"
                />
            </div>

            <div className="ml-4 flex flex-1 flex-col">
                <div>
                    <div className="flex justify-between text-base font-medium text-gray-900">
                        <h3>
                            {product.classe.descrizione_dict ? product.classe.descrizione_dict.testo : product.classe.cod_cl}
                        </h3>
                        <p className="ml-4">{product.totale}€</p>
                    </div>
                    {/*<p className="mt-1 text-sm text-gray-500">{product.color}</p>*/}
                </div>
                <CartItemQta
                    product={product}
                    productIdx={productIdx}
                    mutateCart={mutateCart}
                />
                <div className={'mt-2 w-full'}>
                    <a
                        type="button"
                        className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm
                        w-full text-center justify-center
                        font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline
                        focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        href={`/classi/${product?.classe.cod_cl}/?productIdx=${productIdx}`}
                    >
                        Modifica
                        <PencilSquareIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                    </a>
                </div>
            </div>
        </>
    )
}