import axios from "axios";
import useSWR from "swr";
import getAuthToken from "../../components/getToken";
axios.defaults.withCredentials = true;
export const fetcher_auth = (url_token) =>
    axios
        .get(url_token[0], { headers: {
                'Authorization': "Bearer " + url_token[1],
                "Content-Type": "application/json",

            },  }).catch((err) => {
    })
        .then((res) => res.data);

export const fetcher_auth_file = (url_token) =>
    axios
        .get(url_token[0], {
            headers: {
                'Authorization': "Bearer " + url_token[1],
                "Content-Type": "application/json",

            },  responseType: 'blob'}).then((res) => res.data);

export const fetcher_no_auth = (url_token) =>
    axios
        .get(url_token[0], { headers: {
                "Content-Type": "application/json",

            },  }).catch((err) => {
    })
        .then((res) => res.data);


export default function GetData(url, auto_refresh = false, refreshInterval = 30000, need_auth = false) {
    let token = getAuthToken();

    /**
     * https://swr.vercel.app/docs/options
     * */
    let settings;
    if (auto_refresh) {
        settings = {
            refreshInterval: refreshInterval, revalidateOnFocus: true
        }
    } else {
        settings = {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        }
    }

    const {data, error, mutate} = useSWR([url, token], !need_auth ? fetcher_no_auth : fetcher_auth, settings)

    // const {data, error} = fetcher_auth(url, token)

    return {data, error, mutate}
}
